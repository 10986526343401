<template>
  <div style="background-color: rgb(11, 11, 11); height: 50rem;" >
    <div >
      <div  v-if="loadCertificate" class="flex-container">
      <center>
        <b-spinner variant="primary" label="Spinning"></b-spinner>
      </center>
      </div>

    <div v-else>
      <div v-if="certificate">

        <div  class="d-flex justify-content-center pt-5">
          <div id="certificateDiv" class="pt-1 w-50 dark-topic-container px-1 pr-4 shadow pt-5"  >
              <b-row>
                <b-col cols="2">
                  <img src="@/assets/images/certificate/logo.jpeg" alt="" class="logo" />
                </b-col>
                <b-col cols="10" class=" text-right" >
                  <img :src="certificate.module_certificate_logo" alt="certificate_logo" class="certificate_logo pt-1" /> 
                  <h3 class="m-0 pt-3" style="text-transform: uppercase;"> <strong>{{certificate.module_name}}!</strong></h3>
                  <span>
                    <p class="pt-2 ">This certificate of completion is awarded to</p>
                  </span>
                  <span>
                      <div style="width: fit-content; margin-left: auto;">
                      <p class="pt-1 pl-1 pr-1" style="font-family: sans-serif;">{{certificate.user_fname}} {{certificate.user_lname}}, {{certificate.school_name}}</p>
                      <hr >
                      </div>
                      Completed a {{certificate.module_desc}}
                  </span>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="4">
                  <img src="@/assets/images/certificate/triangles.jpeg" alt="" class="logo" />
                </b-col>
                <b-col cols="4">
                  <div class="p-2 pt-3 text-right">
                    <hr >
                    <p class="m-0 signature-title" ><strong>{{certificate.module_author_name}}</strong></p>
                    <p > {{certificate.module_author_title}}</p>
                    
                  </div>
                </b-col>
                <b-col cols="4">
                  <div class=" pt-3 text-right">
                    <hr >
                    <p class="m-0 signature-title" ><strong> Berkan cokdegerli</strong></p>
                    <p > The Hustle Lab Co-founder</p>
                    
                  </div>
                </b-col>
                
              </b-row>
        </div>
        </div>
        
        <div class="p-4 d-flex justify-content-center" >
            
            <div class="pt-3 pl-1">
              <b-button 
                class="btn rounded-pill btn-sm pl-4 pr-4"
                variant="outline-light"
                @click="downloadCertificate" 
                :disabled="download"
              >
              <feather-icon
                icon="DownloadIcon"
                size="16"
              />  Download
              </b-button>
            </div>
            
            
        </div>
      </div>
      <center v-else class="flex-container">
          <strong> No Certificate Yet</strong>
      </center>
    </div>
    </div>
  </div>
</template>

<script>
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapGetters } from "vuex";

import html2canvas from 'html2canvas';
import {
  BTable,
  BButton,
  BFormCheckbox,
  BCard,
  BRow,
  BCol,
  BBadge,
  BCardText,
  BAvatar,
  VBTooltip,
  BLink,
  BSpinner,
  BPagination,
  BInputGroup,
  BFormInput,
  BTab, BTabs,
  BFormTextarea,
  BFormFile,
  BForm
} from "bootstrap-vue";

export default {
  name: "topics",
  components: {
    BTable,
    BButton,
    BFormCheckbox,
    BCard,
    BRow,
    BCol,
    BBadge,
    BAvatar,
    BCardText,
    BLink,
    BSpinner,
    BPagination,
    ToastificationContent,
    BTab, BTabs,
    BInputGroup,
    BFormInput,
    BFormTextarea,
    BFormFile,
    BForm,
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip
  },
  computed: {
    ...mapGetters({
        certificate: "students/certificate",
        loadCertificate: "students/loadCertificate",
    }),
    st(){
        return this.$route.params.st ? this.$route.params.st : null
    },
    mod(){
        return this.$route.params.mod ? this.$route.params.mod : null
    },
    
  },
  data() {
    return {
      grade: 0,
      comment: null,
      download:false,
    };
  },
  
  methods: {
    init(){
      const payloadCert = {query: {user_id:this.st,module_id:this.mod, hash:true}}
      this.$store.dispatch("students/certificate", payloadCert).then(_=>{

      }).catch(error=>{

        
        this.$swal({
              icon: "error",
              title: `<h4>${error.response.data.error}</h4>`,
              showConfirmButton: true,
              confirmButtonColor: "#E84185",
              allowOutsideClick: true
            });
          
      })
    },
    downloadCertificate() {
        this.download = true;
        const certificateDiv = document.getElementById('certificateDiv');
        html2canvas(certificateDiv).then(canvas => {
            const link = document.createElement('a');
            link.href = canvas.toDataURL('image/png');
            link.download = this.certificate.module_name+'_'+this.certificate.user_fname+' certificate.png';
            link.click();
        });
        this.download = false;
    }
  },
  mounted() {
    this.init()
  }
};
</script>
<style lang="css">
.custom-input-grade {
  background-color: #f8f9fa; /* Gray background */
  width: 150px; /* Custom width */
  padding: 0.5rem; /* Optional: Adjust padding */
}
[dir] .nav-tabs {
    margin-bottom: 1rem !important;
    background-color: #fff !important;
    padding-top: 10px;
}
.cutom-tabs-dark .nav-tabs{
    background-color: rgb(11, 11, 11) !important;
}
.dark-topic-container{
        border: 1px solid #3A3A3A;
        border-radius: 20px;
        background-color: #000000;
    }
  .signature-title{
    text-transform: uppercase;
    color: #e4229a;
  }
  .circle-icon {
    color: #000000;
    background-color: #f8f9fa;
    width: 2rem;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%; /* Makes it a circle */
    border: 2px solid #1b1b1c; /* Adds the border */
  }
  .default-icon {
    color: #000000;
    background-color: #f8f9fa;
    width: 2rem;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius:5px; /* Makes it a circle */
    border: 2px solid #1b1b1c; /* Adds the border */
  }
  .flex-container {
    display: flex;
    justify-content: center; 
    align-items: center;     
    height: 100vh;           
}
.certificate_logo{
    width: 96px;
    height: 45px;
  }
</style>